[class*='cookiebar--'],
.cookiebar-outer {
  display: none;

  &.is-fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($c-black, 0.85);
    z-index: 998;
  }
}
.cookiebar {
  @include scrollbars;

  position: fixed;
  right: $spacing;
  bottom: $spacing;
  overflow-y: scroll;
  width: calc(100% - #{$spacing * 2});
  max-width: 460px;
  max-height: calc(100vh - #{$spacing * 2});
  background-color: $c-black;
  color: $c-white;
}

// Header

.cookiebar__header {
  padding: $spacing;
}

.cookiebar__header__title {
  @extend %fw-bold;
  font-size: 18px;
  line-height: 1.3em;
}

.cookiebar__header__text {
  margin-top: $spacing;
  font-size: 14px;
  line-height: 1.3em;
}

.cookiebar__header__actions {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing;
  border-top: 1px solid $c-white;
  padding-top: $spacing;
}

.cookiebar__header__actions__details,
.cookiebar__header__actions__validate {
  @extend %fw-bold;
  font-size: 16px;
  line-height: 1.3em;
  cursor: pointer;
}

.cookiebar__header__actions__validate {
  @extend %text-uppercase;
  margin-left: $spacing;
}

// Inner
.cookiebar-inner {
  background-color: $c-gray-dark;
}

// Actions

.cookiebar__actions {
  position: relative;
  overflow: hidden;
  height: 0;

  &.is-open {
    height: auto;
  }
}

.cookiebar__actions__list {
  @extend %list-nostyle;
  width: calc(100% - #{$spacing * 2});
  margin: $spacing auto;
}

.cookiebar__actions__item {
  & + & {
    margin-top: $spacing / 4;
  }
}

// Action

.cookiebar-action-inner {
  display: flex;
  align-items: center;
}

// Checkbox

.cookiebar-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.cookiebar-checkbox__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.cookiebar-checkbox__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000;
  border-radius: 0px;

  &::after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .cookiebar-checkbox:hover &,
  .cookiebar-checkbox__input:focus ~ & {
    background: #ccc;
  }

  .cookiebar-checkbox__input:checked ~ & {
    background: #2aa1c0;
  }

  .cookiebar-checkbox:hover .cookiebar-checkbox__input:not([disabled]):checked ~ &,
  .cookiebar-checkbox__input:checked:focus ~ & {
    background: #0e6647;
  }

  .cookiebar-checkbox__input:disabled ~ & {
    background: #e6e6e6;
    opacity: .6;
    pointer-events: none;
  }

  .cookiebar-checkbox__input:checked ~ &:after {
    display: block;
  }

  .cookiebar-checkbox__input:disabled ~ &:after {
      border-color: #7b7b7b;
  }
}

.cookiebar-action__more-trigger {
  position: relative;
  top: -3px;
  display: block;
  width: 14px;
  height: 14px;
  margin-left: $spacing / 2;
  cursor: pointer;
}

.cookiebar-action__more-trigger__icon {
  display: block;
  width: 14px;
  height: 14px;
  fill: $c-white;
  transition: fill .2s ease-out;

  .cookiebar-action__more-trigger:hover & {
    fill: $c-black;
  }

}

// More

.cookiebar-more {
  overflow: hidden;
  height: 0;

  &.is-open {
    height: auto;
  }
}

.cookiebar-more-inner {
  width: 100%;
  margin: $spacing / 2 0 $spacing;
  padding: $spacing / 2;
  background-color: $c-gray-medium;

  .cookiebar__actions__item:last-child & {
    margin: $spacing / 2 0;
  }
}

.cookiebar-more__text {
  @extend %fw-bold;
  font-size: 12px;
  line-height: 1.3em;
}

.cookiebar-more__table {
  width: 100%;
  margin-top: $spacing / 2;
  border: 1px solid $c-gray-medium;
  font-size: 12px;
  line-height: 1.3em;
  text-align: left;
}

.cookiebar-more__table__row {
  display: flex;

  & + & {
    border-top: 1px solid $c-gray-light;
  }
}

.cookiebar-more__table__cell {
  padding: $spacing / 4 0;

  &:first-child {
    flex-shrink: 0;
    width: 30%;
    margin-right: $spacing / 2;
  }
}
