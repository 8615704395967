/**
 * Dev, based on $env (from gulp)
 */
$env: 'dev' !default;


/**
 * Colors
 * for the naming use http://name-of-color.com/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: rgb(32, 32, 32);
$c-gray-darker: rgb(64, 64, 64);
$c-gray-dark: rgb(96, 96, 96);
$c-gray-medium: rgb(128, 128, 128);
$c-gray-light: rgb(160, 160, 160);
$c-gray-lighter: rgb(192, 192, 192);
$c-gray-lightest: rgb(224, 224, 224);
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;

// Semantic/project colors
$c-dark: $c-black;
$c-light: $c-white;

// Z Layers
$z-layers: (
  lowest: 0,
  lower: 10,
  low: 20,
  high: 30,
  higher: 40,
  highest: 50,
  infinite: 999,
);

/**
 * Typography
 */

$base-font-size: 18px !default;

$ff-default: sans-serif;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem
);

// The `scalable-text-map` contains options for our `scalable-text` mixin.
// For each entry, we define a `min` map and a `max` map.
// In this example this means:
// - For a screen < 400px, the `h1` will be 24px.
// - For a screen > 800px, the `h1` will be 36px.
// - For any screen size in between, the `h1` will scale between 24px and 36px.
$scalable-text-map: (
  h1: (
    min: (24px, 'small'),
    max: (36px, 'large')
  ),
  p: (
    min: (13px, 'small'),
    max: (18px, 'large')
  )
);


/*
 * Layout & spacing
 */

$spacing: 20px;


/**
 * RWD
 */
$mq-responsive: true;
$mq-static-breakpoint: 'large';
